*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  @apply text-reset scroll-smooth min-h-screen box-border;
}

body {
  @apply flex flex-col min-h-screen;
}

main {
  @apply flex-grow;
}