.accordion {
  &__icon {
    @apply rotate-0;
  }

  &.expanded {
    .accordion__icon {
      @apply rotate-180;
    }
  }
}

.matrix--accordion .accordion {
  &.expanded {
    @apply shadow-[inset_0_7px_0_0_theme(colors.forest-green)] border-t-forest-green;
  }
}

.matrix--accordion + .matrix--accordion {
  .accordion {
    @apply -mt-[calc(1px+(theme(spacing.10)))] sm:-mt-[calc(1px+(theme(spacing.10-lg)))];
  }
}