.lc--nbm {
  & > *:last-child {
    @apply mb-0;
  }
}

.fc--ntm {
  & > *:first-child {
    @apply mt-0;
  }
}