.site-header__burger-menu {
  &--close {
    @apply hidden;
  }

  &.active {
    .site-header__burger-menu--open {
      @apply hidden;
    }

    .site-header__burger-menu--close {
      @apply block;
    }
  }
}

.site-header--nav {
  // show/hide
  &.active {
    @apply block;
  }
}

.header-nav__menu-bar,
.header-nav__panel,
.header-nav__sub {
  & > li {
    @apply before:content-none;
    
    & > span {
      // do we need to include text underline here?
      @include accessible-focus-state();
    }
  }
}

.header-nav__panel--trigger {
  cursor: pointer;
  
  svg {
    @apply transition-all duration-200;
  }

  &.active {
    svg {
      @apply -rotate-180;
    }

    .header-nav__panel--pointer {
      @apply block;
    }
  }
}

.header-nav__panel {
  // show/hide
  &.active {
    @apply block;
  }
}

.header-nav--overlay {
  @apply hidden;

  &.active {
    @apply block;
  }
}