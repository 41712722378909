// @font-face {
// 	font-family: neue haas grotesk display pro;
// 	font-style: normal;
// 	font-weight: 300;
// 	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayThin.woff) format('woff')
// }

// @font-face {
// 	font-family: neue haas grotesk display pro;
// 	font-style: italic;
// 	font-weight: 300;
// 	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayThinItalic.woff) format('woff')
// }

// @font-face {
// 	font-family: neue haas grotesk display pro;
// 	font-style: normal;
// 	font-weight: 400;
// 	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayLight.woff) format('woff')
// }

// @font-face {
// 	font-family: neue haas grotesk display pro;
// 	font-style: italic;
// 	font-weight: 400;
// 	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayLightItalic.woff) format('woff')
// }

@font-face {
	font-family: neue haas grotesk display pro;
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayRoman.woff) format('woff')
}

@font-face {
	font-family: neue haas grotesk display pro;
	font-style: italic;
	font-weight: 500;
	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayRomanItalic.woff) format('woff')
}

@font-face {
	font-family: neue haas grotesk display pro;
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayMediu.woff) format('woff')
}

@font-face {
	font-family: neue haas grotesk display pro;
	font-style: italic;
	font-weight: 600;
	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayMediumItalic.woff) format('woff')
}

// @font-face {
// 	font-family: neue haas grotesk display pro;
// 	font-style: normal;
// 	font-weight: 700;
// 	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayBold.woff) format('woff')
// }

// @font-face {
// 	font-family: neue haas grotesk display pro;
// 	font-style: italic;
// 	font-weight: 700;
// 	src: url(https://fonts.cdnfonts.com/s/47998/NeueHaasDisplayBoldItalic.woff) format('woff')
// }